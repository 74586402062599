var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "main",
    [
      _c(
        "div",
        {
          staticClass: "header",
          class: {
            active: _vm.headerActive,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "container",
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "jr-logo",
                  attrs: {
                    to: {
                      name: "home",
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/event/trial/jr-logo.svg"),
                      alt: "WUWOW JR Logo",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "form-upper",
        },
        [
          _c(
            "div",
            {
              staticClass: "title-area",
            },
            [
              _c(
                "div",
                {
                  staticClass: "main-title-phone data-scroll",
                  attrs: {
                    "data-scroll": "",
                  },
                },
                [
                  _c("picture", [
                    _c("source", {
                      attrs: {
                        srcset: require("@/assets/event/caves-education-authorize/banner-title.png"),
                        media: "(min-width:1920px)",
                      },
                    }),
                    _c("source", {
                      attrs: {
                        srcset: require("@/assets/event/caves-education-authorize/banner-title-xl.png"),
                        media: "(min-width:1240px)",
                      },
                    }),
                    _c("img", {
                      attrs: {
                        src: require("@/assets/event/caves-education-authorize/banner-title-sm.png"),
                      },
                    }),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "form-visual",
            },
            [
              _c(
                "div",
                {
                  staticClass: "visual-background",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "position-relative",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/event/caves-education-authorize/landing-major.png"),
                          alt: "表單主視覺背景",
                        },
                      }),
                      _c("img", {
                        staticClass: "shine",
                        attrs: {
                          src: require("@/assets/event/caves-education-authorize/shine-bg.png"),
                          alt: "旋轉",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c("img", {
                staticClass: "spark spark-small",
                attrs: {
                  src: require("@/assets/event/caves-education-authorize/spark-small.png"),
                  alt: "星型裝飾",
                },
              }),
              _c("img", {
                staticClass: "spark spark-small2",
                attrs: {
                  src: require("@/assets/event/caves-education-authorize/spark-small.png"),
                  alt: "星型裝飾",
                },
              }),
              _c("img", {
                staticClass: "spark spark-medium",
                attrs: {
                  src: require("@/assets/event/caves-education-authorize/spark-medium.png"),
                  alt: "星型裝飾",
                },
              }),
              _c("img", {
                staticClass: "spark spark-large",
                attrs: {
                  src: require("@/assets/event/caves-education-authorize/spark-large.png"),
                  alt: "星型裝飾",
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "form-area",
            },
            [
              _c(
                "div",
                {
                  staticClass: "title-area",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "main-title data-scroll",
                      attrs: {
                        "data-scroll": "",
                      },
                    },
                    [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            srcset: require("@/assets/event/caves-education-authorize/banner-title.png"),
                            media: "(min-width:1920px)",
                          },
                        }),
                        _c("source", {
                          attrs: {
                            srcset: require("@/assets/event/caves-education-authorize/banner-title-xl.png"),
                            media: "(min-width:1240px)",
                          },
                        }),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/event/caves-education-authorize/banner-title-sm.png"),
                          },
                        }),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-box",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-card",
                      attrs: {
                        "data-scroll": "",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "form-input",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "icon",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/event/trial/member-orange.svg"),
                                },
                              }),
                            ]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formUpper.parentName,
                                expression: "formUpper.parentName",
                              },
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "家長姓名（必填）",
                            },
                            domProps: {
                              value: _vm.formUpper.parentName,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formUpper,
                                  "parentName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-input",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "icon",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/event/trial/phone-orange.svg"),
                                },
                              }),
                            ]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formUpper.parentPhone,
                                expression: "formUpper.parentPhone",
                              },
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "家長電話（必填）",
                            },
                            domProps: {
                              value: _vm.formUpper.parentPhone,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formUpper,
                                  "parentPhone",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-input",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "icon",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/event/trial/mail-orange.svg"),
                                },
                              }),
                            ]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formUpper.parentEmail,
                                expression: "formUpper.parentEmail",
                              },
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "家長email（必填）",
                            },
                            domProps: {
                              value: _vm.formUpper.parentEmail,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formUpper,
                                  "parentEmail",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-privacy",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formUpper.privacyPolicyCheck,
                                expression: "formUpper.privacyPolicyCheck",
                              },
                            ],
                            attrs: {
                              id: "privacy-upper",
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.formUpper.privacyPolicyCheck
                              )
                                ? _vm._i(
                                    _vm.formUpper.privacyPolicyCheck,
                                    null
                                  ) > -1
                                : _vm.formUpper.privacyPolicyCheck,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.formUpper.privacyPolicyCheck,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.formUpper,
                                        "privacyPolicyCheck",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.formUpper,
                                        "privacyPolicyCheck",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.formUpper,
                                    "privacyPolicyCheck",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            {
                              attrs: {
                                for: "privacy-upper",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/event/trial/check.svg"),
                                },
                              }),
                            ]
                          ),
                          _c(
                            "span",
                            [
                              _vm._v("我同意 WUWOW 線上英文 "),
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      path: _vm.privacyUrl,
                                    },
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("隱私權政策")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "form-btn",
                          on: {
                            click: function ($event) {
                              return _vm.submitForm(_vm.formUpper)
                            },
                          },
                        },
                        [_vm._v("免費搶先預約")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "form-divider",
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "object-fit": "contain",
                            },
                            attrs: {
                              src: require("@/assets/event/trial/divider-left.svg"),
                            },
                          }),
                          _c("span", [_vm._v("兒童線上英文")]),
                          _c("img", {
                            staticStyle: {
                              "object-fit": "contain",
                            },
                            attrs: {
                              src: require("@/assets/event/trial/divider-right.svg"),
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "gift-area mb-2 mb-xl-4",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "gift w-100 ml-0",
                              on: {
                                click: function ($event) {
                                  return _vm.openAbilitiesUpbringingModal()
                                },
                              },
                            },
                            [
                              _c("span"),
                              _c("span"),
                              _c("span"),
                              _c("span"),
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/event/trial/gift.svg"),
                                },
                              }),
                              _vm._v(" 家教課1堂＋英文能力報告"),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "gift-area",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "gift w-100 ml-0",
                              attrs: {
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.galleryShow = true
                                },
                              },
                            },
                            [
                              _c("span"),
                              _c("span"),
                              _c("span"),
                              _c("span"),
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/event/trial/gift.svg"),
                                },
                              }),
                              _vm._v("敦煌專屬兒美單字書 (附音檔)"),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "features",
        },
        [
          _c(
            "div",
            {
              staticClass: "feature-plan",
            },
            [
              _c("picture", [
                _c("source", {
                  attrs: {
                    srcset: require("@/assets/event/caves-education-authorize/feature-plan.png"),
                    media: "(min-width:1240px)",
                  },
                }),
                _c("img", {
                  attrs: {
                    src: require("@/assets/event/caves-education-authorize/feature-plan-sm.png"),
                  },
                }),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "features-background",
            },
            [
              _c(
                "div",
                {
                  staticClass: "kid",
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/event/caves-education-authorize/features-background-kid.png"),
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "features-container",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "features-title text-center data-scroll",
                      attrs: {
                        "data-scroll": "",
                      },
                    },
                    [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            srcset: require("@/assets/event/caves-education-authorize/feature-title-xl.png"),
                            alt: "WUWOW JUNIOR 四大特色",
                            media: "(min-width:1920px)",
                          },
                        }),
                        _c("source", {
                          attrs: {
                            srcset: require("@/assets/event/caves-education-authorize/feature-title-sm.png"),
                            alt: "WUWOW JUNIOR 四大特色",
                            media: "(min-width:768px)",
                          },
                        }),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/event/caves-education-authorize/feature-title.png"),
                            alt: "WUWOW JUNIOR 四大特色",
                          },
                        }),
                      ]),
                    ]
                  ),
                  _c(
                    "ul",
                    {
                      staticClass: "features-main",
                    },
                    [
                      _c(
                        "li",
                        {
                          attrs: {
                            "data-scroll": "",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "features-card",
                            },
                            [
                              _c(
                                "h1",
                                {
                                  staticClass: "features-number",
                                  attrs: {
                                    "data-storke": "1",
                                  },
                                },
                                [_vm._v("1")]
                              ),
                              _vm._m(0),
                              _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/event/caves-education-authorize/feature-card1.png"),
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "features-content",
                                },
                                [
                                  _vm._m(1),
                                  _c("p", [
                                    _vm._v(
                                      "教材多元豐富，課後附英檢題型、音檔，學英文同時跨領域培養 21世紀能力、素養。"
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-center",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-xl features-order",
                                          attrs: {
                                            type: "button",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.scrollSection(
                                                ".features-banner"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("瞭解更多")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        {
                          attrs: {
                            "data-scroll": "",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "features-card",
                            },
                            [
                              _c(
                                "h1",
                                {
                                  staticClass: "features-number",
                                  attrs: {
                                    "data-storke": "2",
                                  },
                                },
                                [_vm._v("2")]
                              ),
                              _vm._m(2),
                              _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/event/caves-education-authorize/feature-card2.png"),
                                  },
                                }),
                              ]),
                              _vm._m(3),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        {
                          attrs: {
                            "data-scroll": "",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "features-card",
                            },
                            [
                              _c(
                                "h1",
                                {
                                  staticClass: "features-number",
                                  attrs: {
                                    "data-storke": "3",
                                  },
                                },
                                [_vm._v("3")]
                              ),
                              _vm._m(4),
                              _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/event/caves-education-authorize/feature-card3.png"),
                                  },
                                }),
                              ]),
                              _vm._m(5),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        {
                          attrs: {
                            "data-scroll": "",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "features-card",
                            },
                            [
                              _c(
                                "h1",
                                {
                                  staticClass: "features-number",
                                  attrs: {
                                    "data-storke": "4",
                                  },
                                },
                                [_vm._v("4")]
                              ),
                              _vm._m(6),
                              _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/event/caves-education-authorize/feature-card4.png"),
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "features-content",
                                },
                                [
                                  _vm._m(7),
                                  _c("p", [
                                    _vm._v(
                                      "對標國際分為12等級，以劍橋兒童英檢作為教學課綱，循序漸進系統化學習。"
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-center",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-xl features-order",
                                          attrs: {
                                            type: "button",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.scrollSection(
                                                ".features-syllabus"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("瞭解更多")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "features-bannerContainer",
            },
            [
              _c(
                "div",
                {
                  staticClass: "features-banner",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "features-kid",
                    },
                    [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            src: require("@/assets/event/caves-education-authorize/material-kid-xl.png"),
                            media: "(min-width:1920px)",
                          },
                        }),
                        _c("source", {
                          attrs: {
                            srcset: require("@/assets/event/caves-education-authorize/material-kid-md.png"),
                            media: "(min-width:768px)",
                          },
                        }),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/event/caves-education-authorize/material-kid-sm.png"),
                          },
                        }),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "title text-center data-scroll",
                      attrs: {
                        "data-scroll": "",
                      },
                    },
                    [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            srcset: require("@/assets/event/caves-education-authorize/material-title-xl.png"),
                            alt: "首家 敦煌教育 授權教材",
                            media: "(min-width:1920px)",
                          },
                        }),
                        _c("source", {
                          attrs: {
                            srcset: require("@/assets/event/caves-education-authorize/material-title-sm.png"),
                            alt: "首家 敦煌教育 授權教材",
                            media: "(min-width:768px)",
                          },
                        }),
                        _c("img", {
                          staticStyle: {
                            "max-width": "100%",
                          },
                          attrs: {
                            src: require("@/assets/event/caves-education-authorize/material-title.png"),
                            alt: "首家 敦煌教育 授權教材",
                          },
                        }),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "material-container",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "material-card",
                          attrs: {
                            "data-scroll": "",
                          },
                        },
                        [
                          _c("picture", [
                            _c("source", {
                              attrs: {
                                srcset: require("@/assets/event/caves-education-authorize/material-card1.png"),
                                media: "(min-width:1920px)",
                              },
                            }),
                            _c("source", {
                              attrs: {
                                srcset: require("@/assets/event/caves-education-authorize/material-card1.png"),
                                media: "(min-width:576px)",
                              },
                            }),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/event/caves-education-authorize/material-card1-m.png"),
                              },
                            }),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "material-card",
                          attrs: {
                            "data-scroll": "",
                          },
                        },
                        [
                          _c("picture", [
                            _c("source", {
                              attrs: {
                                srcset: require("@/assets/event/caves-education-authorize/material-card2.png"),
                                alt: "WUWOW JUNIOR 四大特色",
                                media: "(min-width:1920px)",
                              },
                            }),
                            _c("source", {
                              attrs: {
                                srcset: require("@/assets/event/caves-education-authorize/material-card2.png"),
                                alt: "WUWOW JUNIOR 四大特色",
                                media: "(min-width:576px)",
                              },
                            }),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/event/caves-education-authorize/material-card2-m.png"),
                                alt: "WUWOW JUNIOR 四大特色",
                              },
                            }),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "material-card",
                          attrs: {
                            "data-scroll": "",
                          },
                        },
                        [
                          _c("picture", [
                            _c("source", {
                              attrs: {
                                srcset: require("@/assets/event/caves-education-authorize/material-card3.png"),
                                alt: "WUWOW JUNIOR 四大特色",
                                media: "(min-width:1920px)",
                              },
                            }),
                            _c("source", {
                              attrs: {
                                srcset: require("@/assets/event/caves-education-authorize/material-card3.png"),
                                alt: "WUWOW JUNIOR 四大特色",
                                media: "(min-width:576px)",
                              },
                            }),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/event/caves-education-authorize/material-card3-m.png"),
                                alt: "WUWOW JUNIOR 四大特色",
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c("div", {
                    staticClass: "material-line",
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "features-arrows",
                      attrs: {
                        "data-scroll": "",
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/event/trial/learnReason-arrow.png"),
                        },
                      }),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/event/trial/learnReason-arrow.png"),
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "features-more",
                          class: {
                            active: _vm.isCheckedMore,
                          },
                          attrs: {
                            for: "btn-check",
                          },
                          on: {
                            click: _vm.toggleCheckMore,
                          },
                        },
                        [_vm._v("點我看更多")]
                      ),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/event/trial/learnReason-arrow.png"),
                        },
                      }),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/event/trial/learnReason-arrow.png"),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCheckedMore,
                          expression: "isCheckedMore",
                        },
                      ],
                      staticClass: "features-table",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "row align-items-center d-",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col-12 col-xl-6 mb-5 mb-xl-0",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center dunhuang",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/material_2023/Dunhuang.svg"),
                                    },
                                  }),
                                  _c("img", {
                                    staticClass: "mx-4",
                                    attrs: {
                                      src: require("@/assets/material_2023/close.svg"),
                                    },
                                  }),
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/material_2023/Caves.svg"),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "p-1",
                                  attrs: {
                                    "data-scroll": "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "與深耕70年外語教育的敦煌教育集團合作，選用 CAVES KIDS 教材，主題內容多元豐富，涵蓋歷史、科技、藝術...，課後提供仿照劍橋兒童英檢題型及練習音檔，按部就班紮實學英文，同時跨領域培養孩子們 21世紀所需的知識能力與素養。"
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col-12 col-xl-6",
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "w-100 cursor-pointer dunhuang-img",
                                attrs: {
                                  "data-scroll": "",
                                  src: require("@/assets/material_2023/video.svg"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.youtubeModal(_vm.materialVideo)
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "bookcaseWrap",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "bookcase-first",
                              attrs: {
                                "data-scroll": "",
                              },
                            },
                            [
                              _c("h3"),
                              _c(
                                "ul",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openMaterialModal(
                                        "modalContentTwo"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "cursor-pointer",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/material_2023/book1.jpg"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "cursor-pointer",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/material_2023/book2.jpg"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "cursor-pointer",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/material_2023/book3.jpg"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "cursor-pointer",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/material_2023/book4.jpg"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "cursor-pointer",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/material_2023/book5.jpg"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "cursor-pointer",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/material_2023/book6.jpg"),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "bookcase-two",
                              attrs: {
                                "data-scroll": "",
                              },
                            },
                            [
                              _c("h3"),
                              _c(
                                "ul",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openMaterialModal(
                                        "modalContentOne"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "cursor-pointer",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/material_2023/book2-1.jpg"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "cursor-pointer",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/material_2023/book2-2.jpg"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "cursor-pointer",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/material_2023/book2-3.jpg"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "cursor-pointer",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/material_2023/book2-4.jpg"),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "features-syllabus-container",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center data-scroll features-syllabus-title",
                      attrs: {
                        "data-scroll": "",
                      },
                    },
                    [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            srcset: require("@/assets/event/caves-education-authorize/syllabus-title-xl.png"),
                            alt: "分級完整 課綱明確",
                            media: "(min-width:1920px)",
                          },
                        }),
                        _c("source", {
                          attrs: {
                            srcset: require("@/assets/event/caves-education-authorize/syllabus-title-sm.png"),
                            alt: "分級完整 課綱明確",
                            media: "(min-width:768px)",
                          },
                        }),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/event/caves-education-authorize/syllabus-title.png"),
                            alt: "分級完整 課綱明確",
                          },
                        }),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "features-syllabus",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "data-scroll position-relative text-center",
                          staticStyle: {
                            "z-index": "2",
                          },
                          attrs: {
                            "data-scroll": "",
                          },
                        },
                        [
                          _c("picture", [
                            _c("source", {
                              attrs: {
                                srcset: require("@/assets/event/caves-education-authorize/syllabus-boy-xl.png"),
                                alt: "快樂學習",
                                media: "(min-width:1920px)",
                              },
                            }),
                            _c("source", {
                              attrs: {
                                srcset: require("@/assets/event/caves-education-authorize/syllabus-boy-sm.png"),
                                alt: "快樂學習",
                                media: "(min-width:768px)",
                              },
                            }),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/event/caves-education-authorize/syllabus-boy.png"),
                                alt: "快樂學習",
                              },
                            }),
                          ]),
                        ]
                      ),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "syllabus-level",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "level-card",
                                attrs: {
                                  "data-scroll": "",
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "level-img",
                                  attrs: {
                                    src: require("@/assets/event/caves-education-authorize/syllabus-level1.png"),
                                  },
                                }),
                                _vm._m(8),
                                _c(
                                  "div",
                                  {
                                    staticClass: "level-line",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/event/caves-education-authorize/syllabus-level1-line.png"),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "level-card",
                                attrs: {
                                  "data-scroll": "",
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "level-img",
                                  attrs: {
                                    src: require("@/assets/event/caves-education-authorize/syllabus-level2.png"),
                                  },
                                }),
                                _vm._m(9),
                                _c(
                                  "div",
                                  {
                                    staticClass: "level-line",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/event/caves-education-authorize/syllabus-level2-line.png"),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "level-card",
                                attrs: {
                                  "data-scroll": "",
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "level-img",
                                  attrs: {
                                    src: require("@/assets/event/caves-education-authorize/syllabus-level3.png"),
                                  },
                                }),
                                _vm._m(10),
                                _c(
                                  "div",
                                  {
                                    staticClass: "level-line",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/event/caves-education-authorize/syllabus-level3-line.png"),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c("div", {
                        staticClass: "gradient-ball",
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c("news"),
      _c("satisfaction"),
      _c(
        "div",
        {
          staticClass: "form-lower",
        },
        [
          _c(
            "div",
            {
              staticClass: "form-area",
            },
            [
              _c("img", {
                staticClass: "form-title mobile",
                attrs: {
                  src: require("@/assets/event/caves-education-authorize/form-lower-title-mobile.png"),
                },
              }),
              _c("img", {
                staticClass: "form-title tablet",
                attrs: {
                  src: require("@/assets/event/caves-education-authorize/form-lower-title-tablet.png"),
                },
              }),
              _c("img", {
                staticClass: "form-title desktop",
                attrs: {
                  src: require("@/assets/event/caves-education-authorize/form-lower-title-desktop.png"),
                },
              }),
              _c(
                "div",
                {
                  staticClass: "form-card",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-input",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "icon",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/event/trial/member-blue.svg"),
                            },
                          }),
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formLower.parentName,
                            expression: "formLower.parentName",
                          },
                        ],
                        attrs: {
                          type: "text",
                          placeholder: "家長姓名（必填）",
                        },
                        domProps: {
                          value: _vm.formLower.parentName,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formLower,
                              "parentName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "form-input",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "icon",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/event/trial/phone-blue.svg"),
                            },
                          }),
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formLower.parentPhone,
                            expression: "formLower.parentPhone",
                          },
                        ],
                        attrs: {
                          type: "text",
                          placeholder: "家長電話（必填）",
                        },
                        domProps: {
                          value: _vm.formLower.parentPhone,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formLower,
                              "parentPhone",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "form-input",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "icon",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/event/trial/mail-blue.svg"),
                            },
                          }),
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formLower.parentEmail,
                            expression: "formLower.parentEmail",
                          },
                        ],
                        attrs: {
                          type: "text",
                          placeholder: "家長email（必填）",
                        },
                        domProps: {
                          value: _vm.formLower.parentEmail,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formLower,
                              "parentEmail",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "form-input",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "icon",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/event/trial/kid-blue.svg"),
                            },
                          }),
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formLower.studentChineseName,
                            expression: "formLower.studentChineseName",
                          },
                        ],
                        attrs: {
                          type: "text",
                          placeholder: "孩子中文姓名（非必填）",
                        },
                        domProps: {
                          value: _vm.formLower.studentChineseName,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formLower,
                              "studentChineseName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "form-input",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "icon",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/event/trial/kid-a-blue.svg"),
                            },
                          }),
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formLower.studentEnglishName,
                            expression: "formLower.studentEnglishName",
                          },
                        ],
                        attrs: {
                          type: "text",
                          placeholder: "小孩英文名稱（非必填）",
                        },
                        domProps: {
                          value: _vm.formLower.studentEnglishName,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formLower,
                              "studentEnglishName",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "form-input",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "icon",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/event/trial/kid-five-blue.svg"),
                            },
                          }),
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formLower.studentAge,
                            expression: "formLower.studentAge",
                          },
                        ],
                        attrs: {
                          type: "text",
                          placeholder: "孩子年齡（非必填）",
                        },
                        domProps: {
                          value: _vm.formLower.studentAge,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formLower,
                              "studentAge",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "form-privacy",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formLower.privacyPolicyCheck,
                            expression: "formLower.privacyPolicyCheck",
                          },
                        ],
                        attrs: {
                          id: "privacy-lower",
                          type: "checkbox",
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.formLower.privacyPolicyCheck
                          )
                            ? _vm._i(_vm.formLower.privacyPolicyCheck, null) >
                              -1
                            : _vm.formLower.privacyPolicyCheck,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.formLower.privacyPolicyCheck,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.formLower,
                                    "privacyPolicyCheck",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.formLower,
                                    "privacyPolicyCheck",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.formLower, "privacyPolicyCheck", $$c)
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          attrs: {
                            for: "privacy-lower",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/event/trial/check.svg"),
                            },
                          }),
                        ]
                      ),
                      _c(
                        "span",
                        [
                          _vm._v("我同意 WUWOW 線上英文 "),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  path: _vm.privacyUrl,
                                },
                                target: "_blank",
                              },
                            },
                            [_vm._v("隱私權政策")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "form-divider",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/event/trial/divider-left.svg"),
                        },
                      }),
                      _c("span", [_vm._v("兒童線上英文")]),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/event/trial/divider-right.svg"),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "gift-area mb-2 mb-xl-4",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "gift w-100 ml-0",
                          on: {
                            click: function ($event) {
                              return _vm.openAbilitiesUpbringingModal()
                            },
                          },
                        },
                        [
                          _c("span"),
                          _c("span"),
                          _c("span"),
                          _c("span"),
                          _c("img", {
                            attrs: {
                              src: require("@/assets/event/trial/gift.svg"),
                            },
                          }),
                          _vm._v(" 家教課1堂＋英文能力報告"),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "gift-area",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "gift w-100 ml-0",
                          attrs: {
                            type: "button",
                          },
                          on: {
                            click: function ($event) {
                              _vm.galleryShow = true
                            },
                          },
                        },
                        [
                          _c("span"),
                          _c("span"),
                          _c("span"),
                          _c("span"),
                          _c("img", {
                            attrs: {
                              src: require("@/assets/event/trial/gift.svg"),
                            },
                          }),
                          _vm._v("敦煌專屬兒美單字書 (附音檔)"),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "form-btn",
                      on: {
                        click: function ($event) {
                          return _vm.submitForm(_vm.formLower)
                        },
                      },
                    },
                    [_vm._v("免費搶先預約")]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "lioshutan-footer bg-lioshutan py-4",
        },
        [
          _c(
            "div",
            {
              staticClass: "d-block d-md-none py-4",
            },
            [
              _c(
                "div",
                {
                  staticClass: "text-center",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "text-decoration-none",
                      attrs: {
                        href: `mailto:${_vm.cooperationUrl}`,
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "h5",
                        {
                          staticClass: "mb-4 text-light",
                        },
                        [_vm._v("合作提案")]
                      ),
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "text-decoration-none",
                      attrs: {
                        href: _vm.joinUsUrl,
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "h5",
                        {
                          staticClass: "mb-4 text-light",
                        },
                        [_vm._v("夥伴招募")]
                      ),
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "text-decoration-none",
                      attrs: {
                        href: _vm.pmRecruitmentUrl,
                        target: "_blank",
                      },
                    },
                    [
                      _c(
                        "h5",
                        {
                          staticClass: "mb-4 text-light",
                        },
                        [_vm._v("成為老師")]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "text-light text-center contact",
                },
                [
                  _c("h5", [_vm._v("聯絡我們")]),
                  _c(
                    "a",
                    {
                      staticClass: "text-light",
                      attrs: {
                        href: "tel:0800885315",
                      },
                    },
                    [_vm._v("Tel: 0800-885315")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "text-light",
                      attrs: {
                        href: "mailto:service@wuwowjr.tw",
                      },
                    },
                    [_vm._v("service@wuwowjr.tw")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex mt-4 justify-content-center",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "mr-1 d-flex flex-column align-items-center text-light",
                          attrs: {
                            href: "https://www.facebook.com/WUWOW.Junior",
                            target: "_blank",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/event/trial/icon-fb.svg"),
                            },
                          }),
                          _c("p", [_vm._v("wuwow jr")]),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._m(11),
              _vm._m(12),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "d-none d-md-block d-lg-none",
            },
            [
              _c(
                "div",
                {
                  staticClass: "container",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "row py-4",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "col-sm-6",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-center",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "text-decoration-none",
                                  attrs: {
                                    href: `mailto:${_vm.cooperationUrl}`,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "mb-4 text-light",
                                    },
                                    [_vm._v("合作提案")]
                                  ),
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "text-decoration-none",
                                  attrs: {
                                    href: _vm.joinUsUrl,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "mb-4 text-light",
                                    },
                                    [_vm._v("夥伴招募")]
                                  ),
                                ]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "text-decoration-none",
                                  attrs: {
                                    href: _vm.pmRecruitmentUrl,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "mb-4 text-light",
                                    },
                                    [_vm._v("成為老師")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "text-light text-center contact",
                            },
                            [
                              _c("h5", [_vm._v("聯絡我們")]),
                              _c(
                                "a",
                                {
                                  staticClass: "text-light",
                                  attrs: {
                                    href: "tel:0800885315",
                                  },
                                },
                                [_vm._v("Tel: 0800-885315")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "text-light",
                                  attrs: {
                                    href: "mailto:service@wuwowjr.tw",
                                  },
                                },
                                [_vm._v("service@wuwowjr.tw")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex mt-4 justify-content-center",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "mr-1 d-flex flex-column align-items-center text-light",
                                      attrs: {
                                        href: "https://www.facebook.com/WUWOW.Junior",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/event/trial/icon-fb.svg"),
                                        },
                                      }),
                                      _c("p", [_vm._v("wuwow jr")]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._m(13),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "d-none d-md-none d-lg-block container",
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-row justify-content-around py-5",
                },
                [
                  _c("div", [
                    _c(
                      "a",
                      {
                        staticClass: "text-decoration-none",
                        attrs: {
                          href: `mailto:${_vm.cooperationUrl}`,
                          target: "_blank",
                        },
                      },
                      [
                        _c(
                          "h5",
                          {
                            staticClass: "mb-4 text-light",
                          },
                          [_vm._v("合作提案")]
                        ),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "text-decoration-none",
                        attrs: {
                          href: _vm.joinUsUrl,
                          target: "_blank",
                        },
                      },
                      [
                        _c(
                          "h5",
                          {
                            staticClass: "mb-4 text-light",
                          },
                          [_vm._v("夥伴招募")]
                        ),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "text-decoration-none",
                        attrs: {
                          href: _vm.pmRecruitmentUrl,
                          target: "_blank",
                        },
                      },
                      [
                        _c(
                          "h5",
                          {
                            staticClass: "mb-4 text-light",
                          },
                          [_vm._v("成為老師")]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "text-light contact",
                    },
                    [
                      _c("h5", [_vm._v("聯絡我們")]),
                      _c(
                        "a",
                        {
                          staticClass: "text-light",
                          attrs: {
                            href: "tel:0800885315",
                          },
                        },
                        [_vm._v("Tel: 0800-885315")]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "text-light",
                          attrs: {
                            href: "mailto:service@wuwowjr.tw",
                          },
                        },
                        [_vm._v("service@wuwowjr.tw")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex mt-4 justify-content-left",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "mr-1 d-flex flex-column align-items-center text-light",
                              attrs: {
                                href: "https://www.facebook.com/WUWOW.Junior",
                                target: "_blank",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/event/trial/icon-fb.svg"),
                                },
                              }),
                              _c("p", [_vm._v("wuwow jr")]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._m(14),
                  _vm._m(15),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "button",
        {
          staticClass: "scrollSection",
          on: {
            click: function ($event) {
              return _vm.scrollTop()
            },
          },
        },
        [
          _c("img", {
            staticClass: "init",
            attrs: {
              src: require("@/assets/event/caves-education-authorize/scroll-top.png"),
            },
          }),
          _c("img", {
            staticClass: "hover",
            attrs: {
              src: require("@/assets/event/caves-education-authorize/scroll-top-hover.png"),
            },
          }),
        ]
      ),
      _c("transition", [
        _vm.doc.isOpen
          ? _c(
              "div",
              {
                staticClass: "docModal",
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "doc-close",
                    on: {
                      click: function ($event) {
                        return _vm.closeAbilitiesUpbringingModal()
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "30",
                          height: "30",
                          viewbox: "0 0 30 30",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z",
                            fill: "white",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm.doc.componentText === "content"
                  ? _c(
                      "div",
                      {
                        staticClass: "d-flex flex-column",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "order-sm-1 order-2",
                          },
                          [
                            _c(
                              "h3",
                              {
                                staticClass: "doc-title",
                              },
                              [_vm._v(_vm._s(_vm.doc.content.title))]
                            ),
                            _c(
                              "h3",
                              {
                                staticClass: "doc-subtitle",
                              },
                              [_vm._v(_vm._s(_vm.doc.content.subTitle))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "doc-content",
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.doc.content.imgPath,
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm.doc.contentTwo.open
                          ? _c(
                              "div",
                              {
                                staticClass: "order-sm-2 order-1",
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "doc-title",
                                  },
                                  [_vm._v(_vm._s(_vm.doc.contentTwo.title))]
                                ),
                                _c(
                                  "h3",
                                  {
                                    staticClass: "doc-subtitle",
                                  },
                                  [_vm._v(_vm._s(_vm.doc.contentTwo.subTitle))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "doc-content",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.doc.contentTwo.imgPath,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm.doc.componentText === "video"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center align-items-center",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "order-sm-1 order-2",
                          },
                          [
                            _c(
                              "h3",
                              {
                                staticClass: "doc-title mb-5",
                              },
                              [_vm._v("兒童英文繪本有聲書")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "videoWrap",
                              },
                              [
                                _c(
                                  "video",
                                  {
                                    attrs: {
                                      controls: "",
                                      autoplay: "",
                                      name: "media",
                                      width: "100%",
                                    },
                                  },
                                  [
                                    _c("source", {
                                      attrs: {
                                        src: "https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/video/2023_jr_king_arthur_legend_the_sword_in_the_stone_voice_book_trailer",
                                        type: "video/mp4",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ]),
      _c("transition", [
        _vm.material
          ? _c(
              "div",
              {
                staticClass: "reasonModal",
                on: {
                  click: function ($event) {
                    if ($event.target !== $event.currentTarget) return null
                    _vm.material = false
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "material-content",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "material-dialog",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "reason-close",
                            on: {
                              click: function ($event) {
                                _vm.material = false
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "30",
                                  height: "30",
                                  viewbox: "0 0 30 30",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z",
                                    fill: "white",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm.materialContent === "modalContentOne"
                          ? [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/material_2023/modalContent-1.png"),
                                },
                              }),
                            ]
                          : [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/material_2023/modalContent-2.svg"),
                                },
                              }),
                            ],
                      ],
                      2
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c("youtube-modal", {
        attrs: {
          src: _vm.youtubeUrl,
        },
      }),
      _c("gallery-modal", {
        attrs: {
          "gallery-show": _vm.galleryShow,
        },
        on: {
          "update:galleryShow": function ($event) {
            _vm.galleryShow = $event
          },
          "update:gallery-show": function ($event) {
            _vm.galleryShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "features-pc",
      },
      [
        _c(
          "h3",
          {
            staticStyle: {
              color: "#FFCE21",
            },
            attrs: {
              "data-storke": "敦煌書局",
            },
          },
          [_vm._v("敦煌書局")]
        ),
        _c(
          "h3",
          {
            staticStyle: {
              color: "#FFCE21",
            },
            attrs: {
              "data-storke": "首家授權兒美教材",
            },
          },
          [_vm._v("首家授權兒美教材")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "features-phone",
      },
      [
        _c(
          "h3",
          {
            staticStyle: {
              color: "#FFCE21",
            },
            attrs: {
              "data-storke": "敦煌書局",
            },
          },
          [_vm._v("敦煌書局")]
        ),
        _c(
          "h3",
          {
            staticStyle: {
              color: "#FFCE21",
            },
            attrs: {
              "data-storke": "首家授權兒美教材",
            },
          },
          [_vm._v("首家授權兒美教材")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "features-pc",
      },
      [
        _c(
          "h3",
          {
            staticStyle: {
              color: "#EB505A",
            },
            attrs: {
              "data-storke": "25分鐘 1對1家教",
            },
          },
          [_vm._v("25分鐘 1對1家教")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "features-content",
      },
      [
        _c(
          "div",
          {
            staticClass: "features-phone",
          },
          [
            _c(
              "h3",
              {
                staticStyle: {
                  color: "#EB505A",
                },
                attrs: {
                  "data-storke": "25分鐘 1對1家教",
                },
              },
              [_vm._v("25分鐘 1對1家教")]
            ),
          ]
        ),
        _c("p", [
          _vm._v(
            "短時間專注學習 加 充分開口機會，家教型 VIP 精緻學習，寶貝進步超有感！"
          ),
        ]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "features-pc",
      },
      [
        _c(
          "h3",
          {
            staticStyle: {
              color: "#71CA2B",
            },
            attrs: {
              "data-storke": "嚴選師資 汰弱留強",
            },
          },
          [_vm._v("嚴選師資 汰弱留強")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "features-content",
      },
      [
        _c(
          "div",
          {
            staticClass: "features-phone",
          },
          [
            _c(
              "h3",
              {
                staticStyle: {
                  color: "#71CA2B",
                },
                attrs: {
                  "data-storke": "嚴選師資 汰弱留強",
                },
              },
              [_vm._v("嚴選師資 汰弱留強")]
            ),
          ]
        ),
        _c("p", [
          _vm._v(
            "教師均有兒美教學經驗及國際教學證照，並定期監督、培訓，維持最佳品質！"
          ),
        ]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "features-pc",
      },
      [
        _c(
          "h3",
          {
            staticStyle: {
              color: "#FFCE21",
            },
            attrs: {
              "data-storke": "分級完整 課綱明確",
            },
          },
          [_vm._v("分級完整 課綱明確")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "features-phone",
      },
      [
        _c(
          "h3",
          {
            staticStyle: {
              color: "#FFCE21",
            },
            attrs: {
              "data-storke": "分級完整 課綱明確",
            },
          },
          [_vm._v("分級完整 課綱明確")]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "level-content",
      },
      [
        _c("h3", [_vm._v("12等級 對標國際")]),
        _c("p", [
          _vm._v(
            "明列12等級各階段學習目標明確，聽、説、讀、寫全方位學習成效更顯著"
          ),
        ]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "level-content",
      },
      [
        _c("h3", [
          _vm._v("融合劍橋英檢與108課綱"),
          _c("br"),
          _vm._v("打造Jr. 新課綱"),
        ]),
        _c("p", [
          _vm._v(
            "明列12等級各階段學習目標明確，聽、説、讀、寫全方位學習成效更顯著"
          ),
        ]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "level-content",
      },
      [
        _c("h3", [_vm._v("0基礎也不擔心")]),
        _c("p", [
          _vm._v(
            "明列12等級各階段學習目標明確，聽、説、讀、寫全方位學習成效更顯著"
          ),
        ]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "a",
      {
        staticClass: "d-flex flex-column align-items-center text-light py-5",
        attrs: {
          href: "https://www.wuwow.tw/",
        },
      },
      [
        _c("img", {
          staticClass: "w-50 mb-3",
          attrs: {
            src: "https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg",
          },
        }),
        _c("h6", [_vm._v("WUWOW")]),
        _c("h6", [_vm._v("最有效的線上英文")]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "a",
      {
        staticClass: "d-flex flex-column align-items-center text-light",
        attrs: {
          href: "/",
        },
      },
      [
        _c("img", {
          staticClass: "w-50 jr-logo mb-3",
          attrs: {
            src: "https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg",
          },
        }),
        _c("h6", [_vm._v("WUWOW Junior")]),
        _c("h6", [_vm._v("專為孩子設計的線上英文")]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "col-sm-6",
      },
      [
        _c(
          "a",
          {
            staticClass:
              "d-flex flex-column align-items-center text-light mb-3",
            attrs: {
              href: "https://www.wuwow.tw/",
            },
          },
          [
            _c("img", {
              staticClass: "w-75 mb-3",
              attrs: {
                src: "https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg",
              },
            }),
            _c("h6", [_vm._v("WUWOW")]),
            _c("h6", [_vm._v("最有效的線上英文")]),
          ]
        ),
        _c(
          "a",
          {
            staticClass: "d-flex flex-column align-items-center text-light",
            attrs: {
              href: "/",
            },
          },
          [
            _c("img", {
              staticClass: "w-75 jr-logo mb-3",
              attrs: {
                src: "https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg",
              },
            }),
            _c("h6", [_vm._v("WUWOW Junior")]),
            _c("h6", [_vm._v("專為孩子設計的線上英文")]),
          ]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "a",
      {
        staticClass: "w-25 d-flex flex-column align-items-center text-light",
        attrs: {
          href: "https://www.wuwow.tw/",
        },
      },
      [
        _c("img", {
          staticClass: "w-75 h-75 mb-3",
          attrs: {
            src: "https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg",
          },
        }),
        _c("h6", [_vm._v("WUWOW")]),
        _c("h6", [_vm._v("最有效的線上英文")]),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "a",
      {
        staticClass: "w-25 d-flex flex-column align-items-center text-light",
        attrs: {
          href: "/",
        },
      },
      [
        _c("img", {
          staticClass: "w-75 h-75 mb-3",
          attrs: {
            src: "https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg",
          },
        }),
        _c("h6", [_vm._v("WUWOW Junior")]),
        _c("h6", [_vm._v("專為孩子設計的線上英文")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }